import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const PolicyCompanyModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        position="absolute"
        top="0"
        m="0"
        borderRadius="0"
        maxHeight="100vh"
        maxWidth="90vw"
      >
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <div style={{ marginTop: 15 }}>
            <p>
              <strong>뷰티벅스 개인정보 처리방침&nbsp;</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                주식회사 에프엘씨굿컴퍼니(이하 "회사")가 운용하는 뷰티벅스(이하
                "사이트")는 정보주체의 자유와 권리 보호를 위해 「개인정보
                보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를
                처리하고 안전하게 관리하고 있습니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보
                처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고
                원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
                처리방침을 수립·공개합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>1. 개인정보의 수집 및 이용목적</strong>
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                회사 또는 사이트가 수집하는 정보는 다음과 같이 이용됩니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                가. 이용자 구분이나 구인/구직정보 에 필요한 정보로 이용되며
                원활한 구인/구직을 위해 회사의 유료직업소개소의 구인/구직
                정보활용에 이용됩니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                나. 통계분석을 통한 고용 및 취업동향 파악 및 고용정책, 마케팅
                자료로서 구인자와 구직자를 위한 적합한 서비스를 제공하는데
                이용됩니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                다. 채용회원은 채용을 희망하는 지역의 구직정보를 웹사이트 또는
                휴대폰 SMS문자서비스를 통해 유상 또는 무상으로 제공받습니다.
                (단, 채용자가 회원가입 시 문자수신 부분에 동의시 통지)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>2) 수집하는 개인정보 항목 및 수집방법</strong>
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                가. 수집하는 개인정보의 항목
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
              <TableContainer
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        구분
                      </Th>
                      <Th textAlign="center" fontSize="lg">
                        항목
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        회원가입
                      </Td>
                      <Td whiteSpace="pre-line">
                        이름, 희망ID, 희망 비밀번호, 전화번호(유선 또는 휴대폰),
                        이메일주소, 이메일 및 SMS 수신 여부
                      </Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        구인 정보 등록
                      </Td>
                      <Td whiteSpace="pre-line">- 주소 / 사진(선택사항)</Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        구직 정보 등록
                      </Td>
                      <Td whiteSpace="pre-line">
                        사진(선택사항), 결혼, 자녀, 국적, 나이,
                        학력/경력(선택사항)
                      </Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        유료 정보 이용
                      </Td>
                      <Td whiteSpace="pre-line">
                        - 신용카드 결제: 카드사명, 카드번호 등 <br />
                        - 은행계좌 이체: 은행명, 계좌번호 등 <br />- 휴대전화
                        결제: 가입자명, 이동전화번호, 결제승인 번호 등
                      </Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        서비스 이용과정이나 사업처리 과정에서 수집되는 정보
                      </Td>
                      <Td whiteSpace="pre-line">
                        IP Address, 쿠키, 방문 일시, 서비스 이용기록, 불량
                        이용기록
                        <br />
                        ※ 기기정보로 이용자를 식별할 수 없습니다.
                        <br />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <span style={{ fontWeight: 400 }}>
                나. 개인정보 수집방법
                <br />
                홈페이지, 서비스 이용, 요금 정산, 이벤트 응모, 고객센터 문의{" "}
                <br />
                다. 고유식별정보 수집
                <br />- 수집 목적 : 채용 보상금 지급에 필요한 세금 업무 처리
                <br />- 수집 정보 : 주민등록번호 또는 외국인등록번호
                <br />- 보유 및 이용기간 : 소득세법 등 관련 법령에 따른 보유기간
                <br />* 근거법령 : 소득세법 제145조 및 제164조, 동법 시행령
                제193조 및 제213조, 국세기본법 제85조의3
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>3. 개인정보의 제공, 처리위탁 및 국외이전</strong>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                회사는 개인정보를 1. 개인정보의 수집 및 이용목적에서 고지한 범위
                내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여
                이용하지 않습니다.
                <br />
                가. 처리위탁
                <br />
                회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고
                있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될
                수 있도록 필요한 사항을 규정하고 있습니다. 변동사항 발생 시
                공지사항 또는
                <br />
                개인정보 처리방침을 통해 고지하도록 하겠습니다.
                <br />
                <br />
              </span>

              <TableContainer
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        수탁업체
                      </Th>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        위탁업무내용
                      </Th>
                      <Th textAlign="center" fontSize="lg">
                        보유 및 이용기간
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td borderRight="1px solid #ddd" textAlign="center"></Td>
                      <Td whiteSpace="pre-line"></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <span style={{ fontWeight: 400 }}>
                <br />
                나. 다음의 경우에는 합당한 절차를 통하여 개인정보를 제공 또는
                이용할 수 있습니다.
                <br />
                1) 이용자가 서비스 이용 중 제3자 제공에 동의한 경우
                <br />
              </span>

              <TableContainer
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        개인정보를 제공받는자
                      </Th>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        개인정보를 제공받는 자의 개인정보 이용 목적
                      </Th>
                      <Th
                        textAlign="center"
                        borderRight="1px solid #ddd"
                        fontSize="lg"
                      >
                        제공하는 개인정보의 항목
                      </Th>
                      <Th textAlign="center" fontSize="lg">
                        개인정보를 제공받는 자의 개인정보 보유 및 이용 기간
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        기업 회원
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        채용 절차 진행
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        프로필(성명, 이메일, 연락처) 이력서(학력, 경력, 자기소개
                        등)
                      </Td>
                      <Td whiteSpace="pre-line">
                        (합격) 인보이스 발행일로부터 90일 뒤 파기 /<br />
                        (불합격) 전형 종료 후 즉시 파기
                      </Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        긱스 클라이언트
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        긱스 전문가
                        <br />
                        채용 절차 진행
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        프로필(성명, 이메일, 연락처) 이력서(학력, 경력, 자기소개
                        등)
                      </Td>
                      <Td whiteSpace="pre-line">
                        (합격) 프로젝트 종료 후 90일뒤 파기
                        <br />
                        (불합격) 지원 일로부터 21일 뒤 파기
                      </Td>
                    </Tr>
                    <Tr _hover={{ bg: "gray.50" }}>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        네이버, 카카오
                        <br />
                        (간편인증)
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        건강보험공단 커리어
                        <br />
                        정보 조회를 위한
                        <br />
                        본인인증
                      </Td>
                      <Td borderRight="1px solid #ddd" textAlign="center">
                        성명, 생년월일, 연락처
                      </Td>
                      <Td whiteSpace="pre-line">본인 인증 후 즉시파기</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <span style={{ fontWeight: 400 }}>
                2) 다만, 관계법령에 의거하거나, 수사 목적으로 법령에 정해진
                절차와 방법에 따라 수사기관의 요구가 있는 경우는 예외로 합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>4. 개인정보의 보유 및 이용기간</strong>
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                사이트는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여
                이용자의 개인정보를 보유 및 이용하게 됩니다. 개인정보의 수집 및
                이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나
                이용기간이
                <br />
                종료한 경우 개인정보를 지체 없이 파기합니다. 단, 다음의 경우에
                대해서는 각각 명시한 이유와 기간 동안 보존합니다.
                <br />
                1) 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우
                법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를
                보유합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만
                이용합니다.
                <br />
                &nbsp;&nbsp; ① 계약 또는 청약철회 등에 관한 기록: 5년
                <br />
                &nbsp;&nbsp; ② 대금결제 및 재화 등의 공급에 관한 기록: 5년
                <br />
                &nbsp;&nbsp; ③ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
                <br />
                &nbsp;&nbsp; ④ 부정이용 등에 관한 기록: 5년
                <br />
                &nbsp;&nbsp; ⑤ 웹사이트 방문기록(로그인 기록, 접속기록): 1년
                <br />
                2) 회원 탈퇴 요청 시, 회사는 탈퇴처리와 동시에 지체 없이
                개인정보를 파기하는 것을 원칙으로 합니다. 단, 회사를 통한 지원
                이력이 있는 회원의 탈퇴 시, 회사는 다음과 같은 보존 이유로 탈퇴
                후 5년 동안 지원내역 및 지원 내역과 관련된 개인정보를
                보관합니다.
                <br />
                &nbsp;&nbsp;① 회사를 통해 취업이 완료되었음에도 기업과의 담합을
                통해 취업 사실을 공유하지 않고 기업의 부정이용에 동참하는 것
                방지
                <br />
                &nbsp;&nbsp;② 회사의 서비스 제공에 관한 기업과의 계약 이행을
                완료하기 위해 회원의 지원정보를 보관할 필요가 있음
                <br />
                3) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와
                개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>5. 이용자의 동의 없는 개인정보의 이용 및 제공</strong>
                <br />
                회사는 원칙적으로 이용자에게 동의 받은 범위 내에서만 개인정보를
                이용 및 제공합니다. 다만, 「개인정보 보호법」제15조 제3항 또는
                제17조 제4항에 따라 이용자의 동의 없이 개인정보를 추가적으로
                이용·제공할
                <br />수 있습니다. 이 경우, 회사는 정보주체의 동의 없는
                개인정보의 추가적인 이용·제공을 위해 아래 사항을 고려합니다.
                <br />
                1) 당초 수집 목적과 관련성이 있는지 여부 보관합니다.
                <br />
                2) 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때
                개인정보의 추가적인 이용 또는 제공에 대한 예측가능성이 있는지
                여부
                <br />
                3) 이용자의 이익을 부당하게 침해하는지 여부
                <br />
                4) 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지
                여부
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>7. 개인정보 파기절차 및 파기방법</strong>
                <br />
                <br />
                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
                달성되면 지체 없이 파기합니다. "회사"의 개인정보 파기절차 및
                방법은 다음과 같습니다.
                <br />
                <br />
                1) 파기절차
                <br />
                이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후
                별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타
                관련법령에 의해 정보보호 사유에 따라 일정 기간 저장된 후
                파기됩니다. 별도 DB로
                <br />
                옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로
                이용되지 않습니다.
                <br />
                <br />
                2) 파기방법
                <br />
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해
                파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                없는 기술적 방법을 사용하여 삭제합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>

              <span style={{ fontWeight: 400 }}>
                <strong>
                  8. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
                </strong>
                <br />
                <br />
                1) 쿠키란
                <br />
                웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는
                작은 텍스트 파일로 이용자의 하드디스크에 저장됩니다.
                <br />
                <br />
                2) 쿠키의 사용 목적
                <br />
                "사이트"가 쿠키를 통해 수집하는 정보는 '2. 수집하는 개인정보
                항목 및 수집방법'과 같으며, '1. 개인정보의 수집 및 이용목적'외의
                용도로는 이용되지 않습니다.
                <br />
                <br />
                3) 쿠키 설치, 운영 및 거부
                <br />
                이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
                웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나,
                쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
                거부할 수도 있습니다. 단, 쿠키의 저장을 거부할 경우에는 로그인이
                필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
                <br />- [설정방법(Microsoft Edge)] : 도구 메뉴 &gt; 설정 &gt;
                쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리 및 삭제
                <br />- [설정방법(Chrome)] : 설정 메뉴 &gt; 고급 설정 표시 &gt;
                개인정보 및 보안 &gt; 사이트 설정 &gt; 쿠키 및 사이트 데이터
                설정
                <br />- [설정방법(Firefox)] : 옵션 메뉴 &gt; 개인정보 및 보안
                &gt; 쿠키 및 사이트 데이터 설정
                <br />- [설정방법(Safari)] : 환경설정 메뉴 &gt; 개인정보 탭 &gt;
                쿠키 및 웹 사이트 데이터 수준 설정
                <br />
                <br />
              </span>

              <span style={{ fontWeight: 400 }}>
                <strong>9. 개인정보의 안전성 확보조치에 관한 사항</strong>
                <br />
                <br />
                1) 개인정보 암호화
                <br />
                이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 각종
                데이터는 암호화하거나 파일 잠금 기능을 통해 별도의 보안기능을
                통해 보호하고 있습니다.
                <br />
                <br />
                2) 해킹 등에 대비한 대책
                <br />
                모든 데이터가 고도의 보안이 유지되는 데이터 센터에 보관되고
                있습니다. 개인정보 데이터의 접근을 사용 권한을 나눠 제한하고
                있으며, 개인PC나 외부 침입이 우려되는 오프라인 공간에 저장하지
                않습니다.
                <br />
                <br />
                3) 개인정보 처리 직원의 교육
                <br />
                개인정보관련 처리 직원은 최소한의 인원으로 구성되며, 새로운
                보안기술 습득 및 개인정보보호 의무에 관해 정기적인 교육을
                실시하며 내부 감사 절차를 통해 보안이 유지되도록
                시행하고있습니다.
                <br />
                <br />
                4) 개인 아이디와 비밀번호 관리
                <br />
                회사는 이용자의 개인정보를 보호하기 위하여 최선의 노력을 다하고
                있습니다. 단, 이용자의 개인적인 부주의로 이메일(또는 구글 등
                외부 서비스와의 연동을 통해 이용자가 설정한 계정 정보),
                비밀번호등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의
                위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
                <br />
                <br />
              </span>

              <span style={{ fontWeight: 400 }}>
                <strong>10. 링크</strong>
                <br />
                <br />
                사이트는 다양한 배너와 링크를 포함할 수 있습니다. 많은 경우 타
                사이트의 페이지와 연결되어 있으며 이는 광고주와의 계약관계에
                의하거나 제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다.
                사이트가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로
                옮겨갈 경우 해당 사이트의 개인정보처리방침은 사이트와 무관하므로
                새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>11. 아동의 개인정보 보호</strong>
                <br />
                <br />
                회사는 인터넷 리크루팅의 특성으로 볼 때 만14세 미만의 아동은
                이력서를 등록하여 구직활동을 할 수 없다고 판단하여 만14세 미만
                아동의 회원가입을 받지 않습니다.
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>12. 비회원의 개인정보 보호</strong>
                <br />
                <br />
                "사이트"에서는 회원 가입을 하지 않아도 공개를 희망하지 않는
                개인정보와 이력서 주요 정보를 제외한 대부분의 컨텐츠를 열람할 수
                있습니다.
                <br />
                <br />
              </span>

              <span style={{ fontWeight: 400 }}>
                <strong>13. 이용자의 권리와 그 행사방법</strong>
                <br />
                <br />
                이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해
                미성년자의 정보를 열람, 공개 및 비공개 처리, 수정, 삭제할 수
                있습니다. 이용자 및 법정대리인은 개인정보
                조회/수정/가입해지(동의철회)를'회원정보관리'를 통해 처리가
                가능하며, 개인정보보호 책임자에게 이메일로 연락하시는 경우에는
                본인 확인 절차를 거친 후 조치하겠습니다. 이용자가 개인정보의
                오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
                개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를
                제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
                없이 통지하여 정정이 이루어지도록 하겠습니다. "회사"는 이용자
                요청에 의해 해지 또는 삭제된 개인정보는 '4. 개인정보의 보유 및
                이용기간'에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
                이용할 수 없도록 처리하고 있습니다.
                <br />
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <strong>15. 개인정보 처리부서 및 민원서비스</strong>
                <br />
                <br />
                "회사"는 이용자의 개인정보를 보호하고 개인정보와 관련한
                고충처리를 위하여 아래와 같이 개인정보 처리 부서 및 연락처를
                지정하고 있습니다.
              </span>

              <TableContainer
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th textAlign="center" fontSize="lg">
                        개인정보 처리 부서
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <span
                          style={{
                            backgroundColor: "yellow",
                            fontWeight: "bold",
                          }}
                        >
                          부서 : 채용플랫폼
                        </span>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <span
                          style={{
                            backgroundColor: "yellow",
                            fontWeight: "bold",
                          }}
                        >
                          연락처 : flcgood.official@gmail.com, 02-2029-9255
                        </span>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <span style={{ fontWeight: 400 }}>
                기타 개인정보에 관한 상담이 필요한 경우에는 아래 기관에 문의하실
                수 있습니다.
                <br />
                <br />
              </span>

              <TableContainer
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td borderRight="1px solid #ddd">개인정보침해신고센터</Td>
                      <Td borderRight="1px solid #ddd">
                        privacy.kisa.or.kr/ 국번없이 118
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderRight="1px solid #ddd">
                        대검찰청 사이버수사과
                      </Td>
                      <Td borderRight="1px solid #ddd">
                        www.spo.go.kr/ 국번없이 1301
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderRight="1px solid #ddd">
                        경찰청 사이버범죄 신고시스템(ECRM)
                      </Td>
                      <Td borderRight="1px solid #ddd">
                        ecrm.police.go.kr / 국번없이 182
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderRight="1px solid #ddd">
                        개인정보 분쟁조정위원회
                      </Td>
                      <Td borderRight="1px solid #ddd">
                        www.kopico.go.kr/ 1833-6972
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <span style={{ fontWeight: 400 }}>
                <br />
                부칙
                <br />본 개인정보처리방침은 2025년 01월 10일 부터 시행하며, 본
                방침이 제정되기 전에 가입 한 회원에게도 동일하게 적용됩니다.
              </span>
            </p>
            <p>&nbsp;</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            닫기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PolicyCompanyModal;
